import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Index from "../views/Index.vue";
import Faq from "../views/Faqs.vue";

const routes = [
	{
		path: "/",
		name: "Index",
		component: Index,
		props: true,
	},
	{
		path: "/ticket-plate/:id",
		name: "Home",
		component: Home,
		props: true,
	},
	{
		path: "/faq",
		name: "FAQ",
		component: Faq,
		props: true,
	},
	{
		path: "*",
    name: "notFound",
    redirect: "/"
	},
];

const router = new VueRouter({
	routes,
	mode: "history",
});

export default router;
