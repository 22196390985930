<template>
	<HelloWorld v-bind:apiEndpoint="url" />
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";

export default {
	name: "Home",
	props: ["id"],
	data() {
		return {
			url: `/web-ticket2/${this.$route.params.id}`,
		};
	},
	components: {
		HelloWorld,
	},
	methods: {
		show() {
			console.log("sdfdsf", this.id);
		},
	},
};
</script>
