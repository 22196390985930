<template>
	<v-toolbar class="chat-header" dark height="56">
		<div class="TicketHeader">
			<img src="../assets/ticketplate_logo.svg" />
		</div>
		<v-spacer></v-spacer>
		<v-toolbar-items>
			<!-- <v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						dark
						v-bind="attrs"
						v-on="on"
						@click="toggleDisplayFaq"
					>
						<v-icon 
						>mdi-help-circle-outline</v-icon>
					</v-btn>
				</template>
				<span>Necesitas ayuda? Haz click en ❔</span>
			</v-tooltip> -->
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						dark
						tag="a"
						v-bind="attrs"
						v-on="on"
						:href="whatsapp_link"
						target="_blank"
					>
						<v-icon size="30">mdi-whatsapp</v-icon>
					</v-btn>
				</template>
				<span>Whatsapp</span>
			</v-tooltip>
		</v-toolbar-items>
	</v-toolbar>
</template>
<script>
import { mapActions } from "vuex";
export default {
	created() {
		this.getTickets();
	},
	methods: {
    ...mapActions(["getTickets", "toggleDisplayFaq"]),
	},
	data() {
		return {
			whatsapp: '584140913875'
		}
	},
	computed: {
		event: function(){
			if(this.$store.state.ticketStore.tickets.filter( ticket => ticket.web_url == this.$route.params.id)[0]) {
				return this.$store.state.ticketStore.tickets.filter( ticket => ticket.web_url == this.$route.params.id)[0]
			}
			return null
		},
		whatsapp_message: function() {
			let eventName = '';
			if(this.$store.state.ticketStore.tickets.filter( ticket => ticket.web_url == this.$route.params.id)[0]) {
				eventName = this.$store.state.ticketStore.tickets.filter( ticket => ticket.web_url == this.$route.params.id)[0].name
			}
			// mensaje a whatsapp
			let str = `Hola, Botones!\nTe escribo desde el ticket store. Estoy interesado en ${ eventName || 'un evento'}`;
					
			return encodeURIComponent(str);
		},
		whatsapp_link: function() {
			if(!this.event) {
				return `https://wa.me/${this.whatsapp.replace(/\+/, '')}?text=${this.whatsapp_message}`;
			}
			let contactNumber = this.event.phone ? this.event.phone : this.whatsapp;
			return `https://wa.me/${contactNumber.replace(/\+/, '')}?text=${this.whatsapp_message}`;
		},
	}
};
</script>
<style lang="scss">
.bot-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	width: 100%;
}
.chat-header {
	background-color: var(--primary-color) !important;
	color: var(--secondary-color) !important;
	* {
		color: var(--secondary-color) !important;
	}
}

</style>
