import Vue from "vue";
import Vuex from 'vuex';   
import Router from 'vue-router';
import App from './App';
import router from "./router";
import VuexStore from "./store";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import VueAnalytics from 'vue-analytics';

Vue.use(Vuex);   
Vue.use(Router);  
Vue.use(Vuetify)

const vuetify = new Vuetify({});
const store = new Vuex.Store(VuexStore);

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default app;