<template>
	<div id="app" :style="styles">
		<router-view />
	</div>
</template>
<script>
export default {
	computed: {
		styles() {
			return {
					"--primary-color": "#22214A",
					"--secondary-color": "#CADFEB",
					"--chat-bubble-color": "#CADFEB",
					"--app-button": "#22214A",
					"--app-button-text": "#FFFFFF",
					"--secondary-app-button": "#22214A",
					"--chat-bg": "transparent",
					"--main-bg": "#ffffff",
					"--main-bg-details": "#ffffff",

			}
		}
	}
}
</script>
<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";
#app {
	font-family: Roboto, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	min-height: calc(var(--vh, 1vh) * 100) !important;
	max-height: calc(var(--vh, 1vh) * 100) !important;
}
.main {
	min-height: calc(var(--vh, 1vh) * 100) !important;
	max-height: calc(var(--vh, 1vh) * 100) !important;
}
.btn {
	* {
		color: unset;
	}
}
</style>
