import { GET_TICKETS } from "./mutationTypes";
import {api} from "../../ajax";

export default {
  state: () => ({
    tickets: [],
    progress: 0
  }),
  mutations: {
    [GET_TICKETS](state, tickets) {
      state.tickets = [...tickets];
    }
  },
  actions: {
    getTickets({ commit }) {
      api
        .get("/web-ticket/get_index")
        .then(response => {
          commit("GET_TICKETS", response.data.data);
        });
    }
  },
  getters: {
    getProgress: state => state.progress
  }
};
