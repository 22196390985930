<template>
	<div class="container">
			<v-row v-for="faq in faqs" :key="faq.id">
				<v-col cols="12">
					<h3>{{ faq.question }}</h3>
				</v-col>
				<v-col cols="12">
					<p>{{ faq.answer }}</p>
				</v-col>
			</v-row>
	</div>
</template>
<script>
import { mapActions } from "vuex";
export default {
	computed: {
		faqs() {
			return this.$store.state.faqs.faqs;
		},
	},
	methods: {
		...mapActions(["getFaqs"]),
	},
	created() {
		this.getFaqs();
	},
};
</script>
<style lang="scss" scoped>
.container {
	background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  * {
    color: var(--secondary-color);
  }
}
</style>
