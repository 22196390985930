<template>
  <div class="card-container">
    <div class="card" @click="$router.push(url)">
      <h1 class="title">{{ title }}</h1>
      <img :src="image" alt="" />
      <p>Ir a la tienda</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ticket: Object
  },
  data() {
    return {
      title: this.ticket.name,
      image: this.ticket.image.url,
      url: `/ticket-plate/${this.ticket.web_url}`
    };
  }
};
</script>
<style lang="scss" scoped>
.card-container {
  margin: 16px;
  min-width: 250px;
  width: 30%;
  height: 350px;
  display: flex;
  .card {
    cursor: pointer;
    height: 100%;
    width: 80%;
    min-width: 250px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background-color: #fefefe;
    border-radius: 10px;
    padding: 8px;
    margin: 0 auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    img {
      min-width: 200px;
      max-width: 90%;
      width: auto;
      height: 260px;
    }
  }
}
.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}
.title:hover {
  background-color: white;
  white-space: unset;
  overflow: visible;
}
.title {
  color: #333;
}

@media only screen and (min-width: 1200px) {
  .card-container {
    width: 20%;
  }
}
</style>
