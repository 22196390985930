import { GET_FAQS } from "./mutationTypes";
import {api} from "../../ajax";

export default {
  state: () => ({
    faqs: [],
    displayFaq: false
  }),
  mutations: {
    [GET_FAQS](state, faqs) {
      state.faqs = [...faqs];
    },
    toggleDisplayFaq(state) {
      state.displayFaq = !state.displayFaq
    }
  },
  actions: {
    getFaqs({ commit }) {
      api
        .get("/ticket/faq/1")
        .then(response => {
          commit(GET_FAQS, response.data);
        });
    },
    toggleDisplayFaq({commit}) {
      commit('toggleDisplayFaq');
    }
  },
  getters: {
    faqs: state => state.faqs,
    displayFaq: state => state.displayFaq
  }
};
