<template>
	<div>
		<v-app-bar color="var(--primary-color)">
			<img class="loguito" src="../assets/ticketplate_logo.svg" />
		</v-app-bar>
		<h1 class="title">Directorio de tickets</h1>
		<v-container>
			<v-row>
				<v-spacer></v-spacer>
				<v-col cols="8">
					<v-text-field
						placeholder="Buscar por nombre"
						filled
						rounded
						outlined
						hide-details="auto"
						background-color="#e6e6e6"
						prepend-inner-icon="mdi-magnify"
						v-model="filter"
					/>
				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>
		<div class="cards-container">
			<ticket-card
				v-for="ticket in tickets"
				:key="ticket.name"
				:ticket="ticket"
			/>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import TicketCard from "../components/TicketCard.vue";
export default {
	components: {
		TicketCard,
	},
	data: () => ({
		filter: "",
	}),
	computed: {
		tickets() {
			let re = new RegExp(this.filter, "gi");
			return this.$store.state.ticketStore.tickets.filter((ticket) => re.test(ticket.name)
			);
		},
	},
	methods: {
		...mapActions(["getTickets"]),
	},
	created() {
		this.getTickets();
	},
};
</script>
<style scoped>
.cards-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 8px;
}
.title {
	margin-top: 20px;
	color: white;
}
.loguito {
	width: 260px;
}
</style>
